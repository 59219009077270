let appNavClosed = true;
import anime from 'animejs/lib/anime.es.js';
import emailjs from 'emailjs-com';

emailjs.init("gyWOo7evuZv5URIhO");

function $(selector) {
    return document.querySelector(selector);
}

function $all(selector) {
    return document.querySelectorAll(selector);
}

function appNavClicked() {
    appNavClosed = !appNavClosed;
    $('.app-nav').style.display = appNavClosed ? 'none' : 'block';
}

const SOLUTIONS_DATA = {
    'analytics': {
        title: 'Healthcare Analytics',
        description: `<p>We provide AI and big data-powered top-tier diagnostic and predictive analytics using our agile, scalable platform and audited high-quality data.</p>
        <p class="mt-5">
        We ingest healthcare focused data from several sources including Patient Data, Service Details, Provider Details, Claims data and labs data and perform high-quality data audits to provide an integrated patient health and clinical data view.</p>`,
        url: '/assets/about..png'
    },
    'customizations': {
        title: 'Deep Customizations',
        description: `<p>Our DPC-centric healthcare data platform is highly configurable and empowers us to tailor our solution to specific DPC requirements.</p>
        <p class="mt-5">
        With cloud-based deployment option and pre-built integrations for common EHRs, our solution is plug-and-play for most of the DPCs.</p>`,
        url: '/assets/payors..jpg'
    },

    'modular': {
        title: 'Modular Features',
        description: `<p>Our DPC-centric robust healthcare analytics solution powered by our agile data platform not only aids in monitoring population health but also tracks cost savings, paving the way for better patient experiences and reduced paperwork.</p>
        <p class="mt-5">Crucially, it plays a pivotal role in preserving the independence of smaller practices. We have a variety of DPC-specific reports and analytics readily-available. However, we also have an option to turn-on and turn-off specific reports and dashboards so that you can always focus on what’s most important to you.</p>`,
        url: '/assets/provider..jpg'
    },
    'optimization': {
        title: 'Cost Optimization',
        description: `<p>We want to convert DPCs into Data-driven powerhouses to enhance patient care while achieving 40-45% efficiency gains for optimal health outcomes.</p>
        <p class="mt-5">
        With digitized records and advanced healthcare analytics, our insights into quality and costs empower DPCs to tailor offerings and elevate the overall healthcare experience.</p>`,
        url: '/assets/hies..jpg'
    },
    dpc: {
        title: 'Direct Primary Care',
        description: `<p>With our solution now better engage with your patients clinical history, and improve the patient experience
        and track the overall healthcare spending and medical utilization.</p>
        <p class="mt-5">
        Our solution will provide you a 360 view of patient's engagements with a PCP, 
        targetting high risk patients, to close the care Gaps.
        our DPC solution accepts data from EMRs,Claims,RX,Health Plans to create a Key KPIs to ease the task of providing 
        quality care, and grow your Business and Operations.</p>`,
        url: '/assets/dpc..jpg'
    },
    payors: {
        title: 'Payors',
        description: `<p>Our advanced Payer Solution enables our clients from payer segments to tell compelling data-driven stories with customizable and portable dashboards with smart graphing capabilities. We offers number of analytics reports that empowers user to make smart decision making on their healthcare data and produce answers in seconds using powerful, proprietary tools such as Power BI. </p>
        <p class="mt-5">
        Users gain confidence in their data with our industry-leading data quality processes and refresh times. They get comprehensive end-to-end data analysis in days, not weeks. With prebuilt, payer-focused content, Our Payer Solution serves up the analytics that best meet the needs of the payer segment through intuitive user interfaces and make them to feel well connected with their customers.</p>`,
        url: '/assets/payors..jpg'
    },

    providers: {
        title: 'Providers',
        description: `<p>Provider Solution helps Provider management leverage their data more effectively and drive business intelligence strategies with access to prebuilt reports, advanced filtering enables, and over data driven methodologies. It enables the seamless flow of information across population health departments with innovative data mart and dashboarding features, such as advanced stratification of patients for care management outreach.</p>
        <p class="mt-5"> Clients get comprehensive end-to-end data analysis in every week refreshes. Our Provider helps ACOs and others to track their at-risk populations and facilitate and prioritize them accordingly.</p>`,
        url: '/assets/provider..jpg'
    },
    hies: {
        title: 'HIES',
        description: `<p>The Payors and Providers now have lots of expections from thier HIEs to help them trasition and work better 
        towards value based care, providing a quality reports, tracking healthcare spending and Medical Utilization.
        Ours solution for HIEs provide a comprehensive list of reports and dashboard to cater the need of provider and Payors through 
        advance analytics and running industry standard algorithms to derive the KPIs.</p>
        <p class="mt-5">
        Our Solution will help in creating a standard pipelines to ingest, Commercial, Medicare and Medicaid data to our Unified data model.
        Once the Data is ingested, we run our Data cleaning and Data Audit Algorithms which can standardize the data and runs various checks to figure out the 
        data completeness, availablility of Key KPIs data points.
        Our one stop solution can explain the complete story of a patients healthcare, engagement with providers,Indentification of High risk patients,
        we provide a KPIs around Clincal Measures,Care gaps protocols, Medical Utilization, Healthcare cost, Saving opportunities.
        Reducing Unnecessary spending and ED visits.</p>`,
        url: '/assets/hies..jpg'
    }
}

const solutionsTab = $all('.solutions-tab');
solutionsTab.forEach((element) => {
    element.addEventListener('click', () => {
        solutionsTabsClicked(element.textContent.trim().toLowerCase());
    })
})

function solutionsTabsClicked(solution) {
    if (!$('.solutions-title')) return;
    const solutionObj = SOLUTIONS_DATA[solution];
    $('.solutions-title').textContent = solutionObj.title;
    $('.solutions-description').innerHTML = solutionObj.description;
    $all('.solutions-img').forEach((ele) => {
        ele.src = solutionObj.url;
    });
    $all('.solutions-tab').forEach((ele) => {
        ele.classList.remove('border-blue-500');
        ele.classList.remove('text-blue-600');
        ele.classList.remove('border-transparent');
        ele.classList.remove('text-gray-700');
    })
    $('.' + solution).classList.add('text-blue-600');
    $('.' + solution).classList.add('border-blue-500');
    anime({
        targets: '.solutions-img',
        opacity: ['0', '1'],
        duration: 500,
        easing: 'easeInOutQuad',
        translateX: [50, 0]
    });
}
solutionsTabsClicked('analytics');

const contactSubmitLoader = $('#contact-submit-loader');
const contactSubmitBtn = $('#contact-submit-btn');
const contactToast = $('#contact-success-toast');
if (contactSubmitLoader) contactSubmitLoader.style.display = 'none';
if (contactToast) contactToast.style.display = 'none';

const appNav = $('#app-nav');
if (appNav) appNav.addEventListener('click', appNavClicked);
contactSubmitBtn.addEventListener('click', submitContactUsForm)

function submitContactUsForm(event) {
    event.preventDefault();
    const form = document.forms.contactForm;
    const formData = new FormData(form);
    contactSubmitLoader.style.display = 'inline-block';

    contactSubmitLoader.disabled = true;
    setTimeout(() => {
        contactSubmitLoader.style.display = 'none';

        showToast(contactToast);
        contactSubmitLoader.removeAttribute('disabled');
    }, 2000)
    const name = formData.get('floating_name');
    const email = formData.get('floating_email');
    const message = formData.get('floating_message');

    var templateParams = {
        from_name: name,
        from_email: email,
        message: message
    };

    emailjs.send('service_t73d2qd', 'template_8mm6gvk', templateParams)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}


function showToast(toast) {
    toast.style.display = 'flex';
    setTimeout(() => {
        toast.style.display = 'none';
    }, 5000);
}